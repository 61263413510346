<template>
  <div>
    <Diary/>
    <b-row>
      <b-col cols="12" md="6">
        <Mood/>
      </b-col>
      <b-col cols="12" md="6">
        <Dashboard />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BButton, BCard, BCardText, BImg, BLink, BCol, BRow} from 'bootstrap-vue'

import Diary from "@/components/bbm/Diary.vue";
import Mood from "@/components/bbm/Mood.vue";
import Dashboard from "@/components/bbm/Dashboard.vue";

export default {
  components: {
    Mood, Diary, Dashboard,
    BButton,
    BImg, BLink,
    BCard,
    BCardText,
    BCol,
    BRow
  },
  data() {
    return {
      user: null,
    }
  },
  created() {
  },
  computed: {
    threeCourses(){ return this.courses.slice(0,3) }
  },
  methods: {
  }
}
</script>

<style lang="scss">
  html .breadcrumbs-top .content-header-title{
    display: none !important;
  }
  .quotation, .quotation-mobile{
    background-size: cover;
    height: 220px;
    background-repeat: no-repeat;
    color: #fff;
    padding-left: 230px;
    padding-right: 30px;
    padding-top: 75px;
    margin-bottom: 30px;
    font-size: 13px;
  }
  @media screen and (max-width: 992px) {
    .quotation{
      display: none;
    }
    .quotation-mobile{
      display: block;
      padding-left: 160px;
      padding-right: 10px;
      font-size: 12px;
      padding-top: 80px;
    }
  }
  @media screen and (min-width: 992px) {
    .quotation{
      display: block;
    }
    .quotation-mobile{
      display: none;
    }
  }

</style>
