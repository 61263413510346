<template>
  <div class="dashboard-container mt-md-5 text-center">
    <BButton variant="outline-primary" size="lg" to="https://babybluematter.com/blog-with-right-sidebar/">
      Przejdź do bazy wiedzy
    </BButton>
  </div>
</template>
<script>
import {BCard, BButton} from  'bootstrap-vue'
export default {
  name: 'Dashboard',
  components: {
    BCard,BButton
  },
  data() {
    return {

    };
  },
  methods: {
    selectMood: (moodId) => {
      console.log(moodId);
    }
  }
}
</script>
<style lang="scss">
.dashboard-container {
  padding: 0;

  h2 {
    font-weight: 600;
    margin-bottom: 15px;
  }
}
</style>
