<template>
  <div class="diary">
    <h5 class="text-center">
      Twój dziennik
    </h5>
    <h2 class="text-center">
      Jak określisz Twoje aktualne emocje?
    </h2>
    <div class="mt-3">
      <div class="input-group mb-1">
        <input type="text"
               class="form-control"
               v-model="message"
               placeholder="Twoje emocje"
               aria-label="Twoje emocje"
               aria-describedby="button-addon2" />
        <div class="input-group-append">
          <button class="btn btn-light" type="button" id="button-addon2" @click="sendMessage">
            <feather-icon
                size="16"
                icon="SendIcon"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {BButton, BCard, BFormCheckbox, BFormGroup, BFormInput, BModal} from 'bootstrap-vue'
export default {
  name: 'Diary',
  components: {
    BFormCheckbox, BModal,
    BButton,
    BCard,
    BFormInput,
    BFormGroup,
  },
  data() {
    return {
      message: ''
    }
  },
  methods: {
    clearMessage() {
      this.message = ''
    },
    sendMessage() {
      this.clearMessage()
    }
  }
}
</script>
<style lang="scss">
.diary {
  padding: 30px 20px;
  background: rgba(77, 170, 200, 1);
  margin-top: -1.5rem;
  margin-left: -16.8px;
  margin-right:  -16.8px;
  h2, h5 {
    color: #ffffff;
  }

  h2 {
    font-weight: 600;
  }

  h5 {
    font-weight: 300;
  }
  .input-group{
    background: #fff;
    border-radius: 0.5rem;
    input{
      height: 40px;
      border-color: #fff;
      color: #333
    }
    button{
      background: #fff;
    }
    .v-field{
      box-shadow: none;
    }
    .v-field__outline{
      display: none;
    }
  }
}
</style>
