<template>
  <div class="text-center mood-container">
    <BCard title="Jak oceniasz Twój nastrój?">
      <b-row no-gutters>
        <b-col cols="2" v-for="item in moods" :key="item.id" >
          <div class="mood cursor-pointer" :class="'mood-'+item.id" @click="selectMood(item.id)">
            <i
                :class="item.icon"
                style="font-size: 36px"
                :key="item.id"
            />
            <span>{{ item.text }}</span>
          </div>
        </b-col>
      </b-row>
    </BCard>
    <BCard title="Jak oceniasz Twój sen?">
      <b-row no-gutters>
        <b-col cols="2" v-for="item in sleep" :key="item.id" >
          <div class="mood cursor-pointer text-center" :class="'mood-'+item.id" @click="selectMood(item.id)">
            <span class="badge badge-light-info" style="font-size: 20px; display: inline-block;">{{ item.text }}</span>
          </div>
        </b-col>
      </b-row>
    </BCard>
    <BCard title="Jak oceniasz swoją aktywność?">
      <b-row no-gutters>
        <b-col cols="2" v-for="item in sleep" :key="item.id" >
          <div class="mood cursor-pointer text-center" :class="'mood-'+item.id" @click="selectMood(item.id)">
            <span class="badge badge-light-info" style="font-size: 20px; display: inline-block;">{{ item.text }}</span>
          </div>
        </b-col>
      </b-row>
    </BCard>
  </div>
</template>
<script>
import {BButton, BCard, BFormCheckbox, BFormGroup, BFormInput, BModal, BCol, BRow, BToast} from "bootstrap-vue";
export default {
  name: 'Mood',
  components: {
    BFormCheckbox, BModal,
    BButton,
    BCol,
    BRow,
    BCard,
    BFormInput,
    BFormGroup,
    BToast
  },
  data() {
    return {
      sleep: [
        {id: 0, icon: 'ti ti-mood-happy', color: '#000', text: '1'},
        {id: 1, icon: 'ti ti-mood-smile', color: '#000', text: '2'},
        {id: 2, icon: 'ti ti-mood-empty', color: '#000', text: '3'},
        {id: 3, icon: 'ti ti-mood-sad-2', color: '#000', text: '4'},
        {id: 4, icon: 'ti ti-mood-nervous', color: '#000', text: '5'},
        {id: 5, icon: 'ti ti-mood-nervous', color: '#000', text: '6'},
      ],
      moods: [
        {id: 0, icon: 'ti ti-mood-happy', color: '#000', text: 'Szczęśliwa'},
        {id: 1, icon: 'ti ti-mood-smile', color: '#000', text: 'Radosna'},
        {id: 2, icon: 'ti ti-mood-empty', color: '#000', text: 'Neutralna'},
        {id: 3, icon: 'ti ti-mood-sad-2', color: '#000', text: 'Smutna'},
        {id: 4, icon: 'ti ti-mood-nervous', color: '#000', text: 'Zirytowana'},
        {id: 5, icon: 'ti ti-mood-angry', color: '#000', text: 'Zła'},
      ]
    };
  },
  methods: {
    selectMood: (moodId) => {
      console.log(moodId);
      global.vm.$bvToast.toast('Zapisano', {
        solid: true,
        variant: 'primary',
      })
    }
  }
}
</script>
<style lang="scss">
.mood-container {
  padding-top: 30px;

  h2 {
    font-weight: 600;
    margin-bottom: 15px;
  }
  .mood{
    .v-icon{
      border: 3px solid #000;
      height: 40px !important;
      width: 40px !important;
      &:hover{
        box-shadow: #3b405c;
        font-size: 40px !important;
      }
    }
    span{
      font-size: 8px;
      display: block;
      color: #666;
    }
    &.mood-0{
      color: #689f38;
    }
    &.mood-1 {
      color: #8bc34a;
    }
    &.mood-2{
      color: #aed581;
    }
    &.mood-3{
      color: #dce775;
    }
    &.mood-4{
      color: #fff176;
    }
    &.mood-5{
      color: #ffeb3b;
    }
  }
}
</style>
